<template>
  <div class="DataCard">
    <div v-if="filterData" class="row justify-center ">
      <div class="block justify-center" style="width: 85%">
        <div class="row q-mt-sm justify-center">
          <!-- Botao Ordenar (Wesley Ribeiro) -->
          <q-btn-dropdown
            icon="sort"
            label="Ordenar"
            style="width: 45%"
            class="text-caption no-border q-mr-sm"
          >
            <q-list>
              <q-item clickable v-close-popup @click="sOldNew">
                <q-item-section>
                  <q-item-label>
                    <q-icon name="date_range" style="font-size: 2rem" />
                    Antigo -> Novo
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="sNewOld">
                <q-item-section>
                  <q-item-label>
                    <q-icon name="date_range" style="font-size: 2rem" />
                    Novo -> Antigo
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="sLgSm">
                <q-item-section>
                  <q-item-label>
                    <q-icon name="attach_money" style="font-size: 2rem" />
                    Maior -> Menor
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="sSmLg">
                <q-item-section>
                  <q-item-label>
                    <q-icon name="attach_money" style="font-size: 2rem" />
                    Menor -> Maior
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
          <!-- Botao Filtrar (Wesley Ribeiro) -->
          <q-btn-dropdown
            icon="filter_list"
            label="Filtrar"
            style="width: 45%"
            class="text-caption no-border q-ml-sm"
          >
            <q-list>
              <q-item
                clickable
                v-close-popup
                v-model="filterB"
                v-for="(branch, index) in uniqBranch"
                :key="index"
                @click="fBranchCode(branch.substr(0, 6))"
              >
                <q-item-section>
                  <q-item-label>{{ branch }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
          <!-- Toggle de acesso ao Histórico de Pedidos (Wesley Ribeiro) -->
          <div v-if="route === 'orders' || route === 'history'">
            <q-toggle
              keep-color
              dense
              class="text-caption q-mt-sm"
              label="Visualizar Histórico"
              color="primary"
              icon="mdi-history"
              v-model="sHistory"
              @input="seeHistory"
            />
          </div>
        </div>
        <!-- Input Buscar e Botão Limpar Filtro -->
        <div class="row q-mx-sm q-my-sm col-xs-11 col-sm-9 justify-evenly">
          <q-input
            type="text"
            label="Buscar"
            style="width: 60%"
            v-model="filterR"
            clearable
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <q-btn
            icon="mdi-filter-remove-outline"
            class="text-caption no-border q-mx-sm q-my-sm"
            @click="onClearFilters"
          />
        </div>
      </div>
    </div>
    <q-scroll-area style="height: 85vh; min-width: 95vw;" v-if="showCards">
      <div class="row justify-center q-ma-md">
        <q-card
          bordered
          square
          v-for="(item, index) in data"
          :key="index"
          style="min-width: 20vw"
          class="col-xs-12 col-sm-3 q-ma-sm q-py-sm"
          :class="item.response === 'pending' ? 'bg-blue-2' : 'bg-white'"
        >
          <q-card-section
            class="q-ma-xs q-pa-none cursor-pointer"
            @click.native="cardClick(item[fieldEventName])"
          >
            <div
              v-for="(field, index) in fields"
              :key="index"
              class="text-center"
            >
              <q-separator
                v-if="field.type === 'separator'"
                spaced
                size="1px"
                color="black"
              />
              <q-badge
                v-else-if="field.type === 'badgeFloating'"
                color="red"
                class="q-mr-sm q-mt-sm"
                floating
                >{{ item[field.field] }}</q-badge
              >
              <q-icon
                v-else-if="field.type === 'icon'"
                size="xl"
                :name="item[field.field]"
                :class="field.class"
              />
              <div v-else>
                <div v-if="field.label" class="row">
                  <div class="col">
                    <span>{{ field.label }}</span>
                  </div>
                  <div class="col">
                    <span :class="field.class">{{
                      formatString(
                        item[field.field],
                        field.type,
                        item[currencyField]
                      )
                    }}</span>
                  </div>
                </div>
                <div v-else class="row">
                  <div class="col">
                    <span :class="field.class">{{
                      formatString(
                        item[field.field],
                        field.type,
                        null,
                        item[currencyField]
                      )
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </q-card-section>
          <q-card-actions
            v-if="actions"
            align="center"
            class="q-ma-none q-pa-none"
          >
            <div v-for="(action, index) in actions" :key="index">
              <q-btn
                dense
                :icon="action.icon"
                :color="
                  action.label !== 'Transferir'
                    ? action.color
                    : action.label === 'Transferir' &&
                      item.response === 'pending'
                    ? 'blue-8'
                    : 'orange'
                "
                :label="
                  action.label !== 'Transferir'
                    ? action.label
                    : action.label === 'Transferir' &&
                      item.response === 'pending'
                    ? 'Confirmar Transf.'
                    : 'Transferir'
                "
                class="q-ma-xs q-pa-none"
                :size="sizeButton"
                v-show="
                  action.label !== 'Transferir' &&
                  action.label !== 'Confirmar Transf.'
                    ? true
                    : item.level === '01' &&
                      (action.label === 'Transferir' ||
                        action.label === 'Confirmar Transf.')
                    ? true
                    : false
                "
                @click="emitEvent(action.eventEmit, item)"
              />
            </div>
          </q-card-actions>
          <q-card-section
            v-if="sHistory"
            align="center"
            class="q-ma-none q-pa-none"
          >
            <q-badge
              v-if="item.status == 'Aprovado'"
              class="text-white justify-center bg-green"
              style="width: 95%"
            >
              {{ item.status }}
            </q-badge>
            <q-badge
              v-if="item.status == 'Pendente'"
              class="text-white justify-center bg-orange"
              style="width: 95%"
            >
              {{ item.status }}
            </q-badge>
            <q-badge
              v-if="item.status == 'Reprovado'"
              class="text-white justify-center bg-red"
              style="width: 95%"
            >
              {{ item.status }}
            </q-badge>
          </q-card-section>
        </q-card>
      </div>
    </q-scroll-area>
  </div>
</template>

<script>
import formatString from "@/helpers/formatString";
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    actions: {
      type: Array,
      required: false
    },
    sizeButton: {
      type: String,
      required: false,
      default: "md"
    },
    fieldEventName: {
      type: String,
      required: false,
      default: "id"
    },
    filterData: {
      type: Boolean,
      required: false,
      default: false
    },
    currencyField: {
      type: String,
      required: false,
      default: "currency"
    }
  },
  data() {
    return {
      sortWeb: null,
      filterR: null,
      filterB: sessionStorage.filterWebB,
      showCards: true,
      sHistory: sessionStorage.history == "true" ? true : false,
      route: this.$route.name
    };
  },
  methods: {
    formatString,
    emitEvent(event, item) {
      this.$emit(event, item);
    },
    cardClick(key) {
      this.$emit("cardClick", key);
    },
    fBranchCode(filterB) {
      if (sessionStorage.filterWebB && !filterB) {
        this.filterB = sessionStorage.filterWebB;
      } else {
        this.filterB = filterB;
        sessionStorage.setItem("filterWebB", filterB);
      }
    },
    onClearFilters() {
      this.filterB = "";
      sessionStorage.removeItem("sortWeb");
      sessionStorage.removeItem("filterWebB");
    },
    sSmLg() {
      this.data.sort((a, b) => a.amount - b.amount);
      this.showCards = false;
      sessionStorage.setItem("sortWeb", "sSmLg");
      this.$nextTick(() => {
        this.showCards = true;
      });
    },
    sLgSm() {
      this.data.sort((a, b) => b.amount - a.amount);
      this.showCards = false;
      sessionStorage.setItem("sortWeb", "sLgSm");
      this.$nextTick(() => {
        this.showCards = true;
      });
    },
    sNewOld() {
      this.data.sort((a, b) => b.issueDate - a.issueDate);
      this.showCards = false;
      sessionStorage.setItem("sortWeb", "sNewOld");
      this.$nextTick(() => {
        this.showCards = true;
      });
    },
    sOldNew() {
      this.data.sort((a, b) => a.issueDate - b.issueDate);
      this.showCards = false;
      sessionStorage.setItem("sortWeb", "sOldNew");
      this.$nextTick(() => {
        this.showCards = true;
      });
    },
    seeHistory(history) {
      this.sHistory = history;

      !sessionStorage.history
        ? sessionStorage.setItem("history", history)
        : (sessionStorage.history = history);

      if (sessionStorage.history == "true") {
        this.$router.push("history");
        this.sHistory = true;
      } else {
        this.$router.push("orders");
        this.sHistory = false;
      }
    }
  },
  computed: {
    uniqBranch() {
      return new Set(this.data.map(item => item.branch.trim()).sort());
    }
  },
  watch: {
    filterR(newValue) {
      this.$emit("filterR", newValue);
    },
    filterB(newValue) {
      this.$emit("filterB", newValue);
    },
    sHistory(newValue) {
      this.$emit("sHistory", newValue);
    }
  }
};
</script>

<style lang="scss">
.DataCard {
  width: 100%;
}
</style>
